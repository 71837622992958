import React, { useEffect, useState } from "react"
import "./header.scss"
import BackgroundImage from "gatsby-background-image"
import CustomMarkdown from "../../utils/customMarkdown"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Modal from "../modal/modal"
// import { convertToBgImage } from "gbimage-bridge"


const Header = ({ Title, Subtitle, FirstCTATitle, FirstCTAURL, SecondCTATitle, SecondCTAURL, ThirdCTATitle, ThirdCTAURL, Background, Icon, HeaderHeight, FooterInformation }) => {

    // let bgImagePlugin = null;
    // if (Background) {
    //     console.log(Background, "test")

    //     const image = getImage(Background)

    //     console.log(getImage(Background), "test image")
    //     bgImagePlugin = convertToBgImage(image)

    // }

    // console.log(bgImagePlugin, "bgimage")

    var headerHeightStyle
    switch (HeaderHeight) {
        case "small":
            headerHeightStyle = { minHeight: '300px' }
            break;
        case "medium":
            headerHeightStyle = { minHeight: '750px' }
            break;
        case "large":
            headerHeightStyle = { height: 'calc(100vh - 85px)' }
            break;

        default:
            break;
    }

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleGtagHeaderReservation = () => {
        if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

            window.gtag('event', 'header-make-a-reservation');

        }
    }


    useEffect(() => {
        if (Background && Background.fluid && Background.fluid && Background.fluid.srcWebp) {
            const preloadLink = document.createElement("link");
            preloadLink.href = Background.fluid.srcWebp;
            preloadLink.rel = "preload";
            preloadLink.as = "image";
            document.head.appendChild(preloadLink);
        }
    }, [])



    return (
        <>

            <BackgroundImage Tag="section" role="img" className="background-zindex" fluid={Background.fluid || ["rgba(0,0,0,0)"]}>
                <div className="header header-container-gradient" style={headerHeightStyle}>

                    <div className="header-content">

                        <div className="logo">
                            {Icon ?
                                <div className="secondary-image">
                                    <GatsbyImage image={Icon?.gatsbyImageData} alt="header image" ></GatsbyImage>
                                </div>
                                : ''
                            }

                            {
                                Title.split('#').length > 1 ?
                                    <div className="title-block">
                                        <h1 className="title">{Title.split('#')[0]}</h1>
                                        <h2 className="subtitle">{Title.split('#')[1]}</h2>
                                        {Title.split('#')[2] ? <span className="split-title">{Title.split('#')[2]}</span> : ""}
                                    </div>
                                    : <h1 className="simple-title">{Title}</h1>
                            }
                        </div>


                        {
                            Subtitle?.split('#').length > 1 ?
                                <div className="subtitle-block">
                                    <p>{Subtitle.split('#')[0]}</p>
                                    <p>{Subtitle.split('#')[1]}</p>
                                    {Subtitle.split('#')[2] ? <span className="split-subtitle">{Subtitle.split('#')[2]}</span> : ""}
                                </div>
                                : <p>{Subtitle}</p>
                        }

                        <div className="">
                            {
                                FirstCTATitle ?
                                    <div className="header__cta">
                                        <a rel="noopener noreferrer" href={FirstCTAURL} className="cta">{FirstCTATitle}</a>
                                    </div>
                                    : ""
                            }
                            {
                                SecondCTATitle ?
                                    <div className="header__cta">
                                        <a rel="noopener noreferrer" href={SecondCTAURL} className="cta">{SecondCTATitle}</a>
                                    </div>
                                    : ""
                            }
                            {
                                ThirdCTATitle ?
                                    ThirdCTATitle.includes("Reservation") ?
                                        <div className="header__cta">
                                            <button onClick={() => { openModal(); handleGtagHeaderReservation() }} className="button-cta">{ThirdCTATitle}</button>
                                            {
                                                showModal && <Modal show={showModal} handleClose={closeModal} />
                                            }
                                        </div>
                                        :
                                        <div className="header__cta">
                                            <a rel="noopener noreferrer" href={ThirdCTAURL} className="cta">{ThirdCTATitle}</a>
                                        </div>
                                    : ""
                            }
                        </div>

                    </div>

                    {
                        FooterInformation ?
                            <div className="header-disclaimer">
                                <CustomMarkdown className="disclaimer-paragraph">{FooterInformation}</CustomMarkdown>
                            </div>
                            : ""
                    }


                </div>
            </BackgroundImage>

        </>
    )
}

export default Header