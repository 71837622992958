import React from "react"
import ReactMarkdown from "react-markdown"
import 'uikit/dist/css/uikit.css'
import UIkit from 'uikit'
import "./html-editor.scss"

const HtmlEditor = ({htmlContent, fullWidth}) => {

    return(
        <>
        <div dangerouslySetInnerHTML={{__html: htmlContent}} className={!fullWidth?"html-editor html-editor-boxedWidth":'html-editor'}>
        </div>
        </>
    )

}

export default HtmlEditor