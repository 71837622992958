import React from "react"
import "./text-image-component.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import CustomMarkdown from "../../utils/customMarkdown"



const TextImageComponent = ({ Title, Description, Image, ImagePosition, Button }) => {

    // let bgImagePlugin = null;
    // if (Background) {
    //     console.log(Background, "test")

    //     const image = getImage(Background)

    //     console.log(getImage(Background), "test image")
    //     bgImagePlugin = convertToBgImage(image)

    // }

    // console.log(bgImagePlugin, "bgimage")



    return (
        <>
            <div className="" uk-scrollspy=" target: > div; delay: 50; repeat: true; hidden: true">
                <div uk-scrollspy-class="uk-animation-fade">

                    {
                        ImagePosition == "left" ?
                            <div className="content">
                                <div className="uk-width-2-3@l uk-width-1-1@m">
                                    {Image ?
                                        <div className="image-section-left">
                                            <GatsbyImage image={Image?.gatsbyImageData} className="image-layout" alt="menu-information"></GatsbyImage>
                                        </div>
                                        : ""
                                    }
                                </div>

                                <div className="information-content uk-width-1-3@l uk-width-1-1@m">
                                    <div className="information-layout">
                                        <h2 className="title">{Title}</h2>
                                        <b></b>
                                        {Description ?
                                            <CustomMarkdown children={Description} />
                                            : ""
                                        }
                                        <div className="buttons-box">
                                            {Button?.map((item, index) => {
                                                return (
                                                    <div className="button-container" key={index}>
                                                        <a href={item.CTAUrl} rel="noopener noreferrer" className="cta">{item.CTALabel}</a>
                                                    </div>
                                                );
                                            })}

                                        </div>

                                    </div>
                                    <div className="background-color-left"></div>

                                </div>
                            </div>
                            :
                            <div className="content">
                                <div className="information-content uk-width-1-3@l uk-width-1-1@m">
                                    <div className="information-layout">
                                        <h2 className="title">{Title}</h2>
                                        <b></b>
                                        {Description ?
                                            <CustomMarkdown children={Description} />
                                            : ""
                                        }
                                        <div className="buttons-box">
                                            {Button?.map((item, index) => {
                                                return (
                                                    <div className="button-container" key={index}>
                                                        <a href={item.CTAUrl} rel="noopener noreferrer" className="cta">{item.CTALabel}</a>
                                                    </div>
                                                );
                                            })}

                                        </div>

                                    </div>
                                    <div className="background-color-right"></div>

                                </div>

                                <div className="uk-width-2-3@l uk-width-1-1@m">
                                    {Image ?
                                        <div className="image-section-right">
                                            <GatsbyImage image={Image?.gatsbyImageData} className="image-layout" alt="menu-information"></GatsbyImage>
                                        </div>
                                        : ""
                                    }
                                </div>
                            </div>
                    }

                </div>
            </div>
        </>
    )
}

export default TextImageComponent