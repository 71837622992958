import React from "react"
import "./contact-component.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import CustomMarkdown from "../../utils/customMarkdown"
import HtmlEditor from "../html-editor/html-editor"



const ContactComponent = ({ Title, Address, HTML }) => {

    return (
        <>

            <div className="contact-container">

                <div className="information-content">
                    <div className="information-layout">
                        <h2 className="contact-title">{Title}</h2>

                        {Address ?
                            <div style={{ textAlign: "center" }}>
                                <CustomMarkdown>{Address}</CustomMarkdown>
                            </div>
                            : ""
                        }

                        <div style={{ width: "100%" }}>
                            <HtmlEditor htmlContent={HTML} fullWidth={true} />
                        </div>

                    </div>


                </div>

            </div>

        </>
    )
}

export default ContactComponent