import React from 'react';
import './modal.scss';
import HtmlEditor from '../html-editor/html-editor';
import { useStaticQuery, graphql } from "gatsby"
import CustomMarkdown from '../../utils/customMarkdown';

const Modal = ({ show, handleClose }) => {

    const data = useStaticQuery(graphql`
    {
        strapiReservationModal {
            Title
            Description
            Buttons {
              CTALabel
              CTAUrl
            }
            HtmlEditor {
              HTML
            }
          }
    }
    `)

    const handleGtagModalReservation = (pressedBtn) => {
        if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

            window.gtag('event', `${pressedBtn}`);

        }
    }

    return (
        <div className={show ? 'modal display-block' : 'modal display-none'}>
            <section className="modal-main">

                <h2 className="modal-title">{data.strapiReservationModal.Title}</h2>
                <b></b>
                {
                    data.strapiReservationModal.Description ?
                        <div className="description">
                            <CustomMarkdown children={data.strapiReservationModal.Description} />
                        </div>
                        : ""
                }

                {
                    data.strapiReservationModal.Buttons.map((item, index) => {
                        return (
                            <div className="button-container" onClick={() => handleGtagModalReservation(item.CTALabel)}>
                                <a href={item.CTAUrl} rel="noopener noreferrer" className="cta">{item.CTALabel}</a>
                            </div>
                        )
                    })
                }

                {
                    data.strapiReservationModal.HtmlEditor ?
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: "20px" }}>
                            <HtmlEditor htmlContent={data.strapiReservationModal.HtmlEditor.HTML} fullWidth={true} className="book-widget" />
                        </div>
                        : ""
                }

                <button className="close-button" onClick={handleClose}>Close X</button>

            </section>
        </div>
    );
};

export default Modal;